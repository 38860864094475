
import './App.css';
import '../Assets/common.css';
import '../Components/Header/header.css';
import '../Components/Body/body.css';
import '../Components/Footer/footer.css';
import '../Components/Body/settings.css';
import '../Components/Body/comments.css'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

/*
import '../Components/Body/analysis.css';
import '../Components/Body/comments.css';
import '../Components/Body/dashboard.css';
import '../Components/Body/highlightMatchfact.css';
import '../Components/Body/user.css';
import '../Components/Footer/footer.css';
import '../Assets/boxicons.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-quill/dist/quill.snow.css'; */
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { useSelector} from 'react-redux'

const About = React.lazy(() => import('../Pages/about'));
const Contact = React.lazy(() => import('../Pages/contact'));
const Highlight = React.lazy(() => import('../Pages/highlight'));
const Home = React.lazy(() => import('../Pages/Home'));

const CreateHl = React.lazy(() => import('../Pages/createHl'));
const LookupMaster = React.lazy(() => import('../Pages/lookupMaster'));
const Players = React.lazy(() => import('../Pages/players'));
const Clubs = React.lazy(() => import('../Pages/clubs')) ;
const Leagues = React.lazy(() => import('../Pages/leagues')) ;
const Fixtures = React.lazy(() => import('../Pages/fixtures'));

const Login =  React.lazy(() => import('../Pages/login')) ;
const DashBoard = React.lazy(() => import('../Pages/dashBoard')) ;
const Unauthorized = React.lazy(() => import('../Pages/unauthorized')) ;
const CommentsAdmin = React.lazy(() => import('../Pages/commentsAdmin')) ;
const PasswordReset = React.lazy(() => import('../Pages/passwordReset')) ;
const PasswordResetConfirm = React.lazy(() => import('../Pages/passwordResetConfirm')) ;
const EmailVerification = React.lazy(() => import('../Pages/emailVerification')) ;
const OTPConfirmation = React.lazy(() => import('../Pages/otpConfirmation'));
const Users = React.lazy(() => import('../Pages/users'));
const Profile = React.lazy(() => import('../Pages/profile')) ;
const ChangePassword = React.lazy(() => import('../Pages/changePassword')) ;
const Visitors = React.lazy(() => import('../Pages/visitors'));




function App() {
    const userState = useSelector((state) => state.auth)
    const {detail, token } = userState || {}


  return (
    // <AxiosInterceptor>
        <BrowserRouter>
        <Suspense>
          <Routes>
              <Route path='/dashboard' element={token && detail?.is_staff ? <DashBoard /> : <Unauthorized />}>
                <Route path='createHl'
                    element={token && detail?.is_staff ? <CreateHl /> : <Unauthorized />} 
                  />
                <Route path='lookup' 
                  element={token && detail?.is_staff ? <LookupMaster /> : <Unauthorized />} 
                />
                <Route path='players'
                element={token && detail?.is_staff ? <Players /> : <Unauthorized />}  
                />
                <Route path='clubs'
                  element={token && detail?.is_staff ? <Clubs /> : <Unauthorized />}
                />
                <Route path='leagues' 
                  element={token && detail?.is_staff ? <Leagues /> : <Unauthorized />} 
                />
                <Route path='fixtures'
                  element={token && detail?.is_staff ? <Fixtures /> : <Unauthorized />} 
                />
                <Route path='users'
                  element={token && detail?.is_staff ? <Users /> : <Unauthorized />}
                />
                <Route path='profile'
                  element={token && detail?.is_staff ? <Profile /> : <Unauthorized />}
                />
                <Route path='passwordChange'
                  element={token && detail?.is_staff ? <ChangePassword /> : <Unauthorized />}
                />
                <Route path='comment'
                  element={token && detail?.is_staff ? <CommentsAdmin /> : <Unauthorized />}
                />
                <Route path='visitors'
                  element={token && detail?.is_staff ? <Visitors /> : <Unauthorized />}
                />
              </Route>
                <Route path='/' exact element={<Home />} />
                <Route path='/about' element={<About />}/>
                <Route path='/contact' element={<Contact />}/>
                <Route path='/highlight'  element={<Highlight />}/>
                <Route path='/login'
                  element={token && detail?.is_staff ? <DashBoard /> : <Login />}
                />
                <Route path='/unauthorized' element={<Unauthorized />} />
                <Route path='/verify-email' element={<EmailVerification />} />
                <Route path='/password-reset-request' element={<PasswordReset />} />
                <Route path='/password-reset-confirm' element={<PasswordResetConfirm />} />
                <Route path='/otp-confirmation' element={<OTPConfirmation />} />
          </Routes>
        </Suspense>
        
      </BrowserRouter>
   
   
  );
}

export default App;
